
/**
 * @name: 仓储管理-出库记录
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 仓储管理-出库记录
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {ICheckinQuery} from "@/apis/storage/checkin/types";
import {deepCopy, exportFile} from "@/utils/common";
import {checkinQueryApi} from "@/apis/storage/checkin";
import {checkoutExportApi, checkoutQueryApi} from "@/apis/storage/checkout";
import {IDistributeRecord} from "@/apis/storage/gateDist/types";
import {exportDistributeRecordApi} from "@/apis/storage/gateDist";
import {ICheckOutQuery} from "@/apis/storage/checkout/types";

@Component({})
export default class storageCheckout extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  //
  checkoutQtyTotal: string = '0.00'
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "出库单ID",
        prop: "id",
        align: 'center',
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true
      },
      {
        label: "出库档口",
        prop: "gateIdList",
        align: "center",
        search: true,
        searchMultiple: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true
      },
      {
        label: "出库数量",
        prop: "checkoutQtyUnit",
        align: 'center',
      },
      {
        label: "批次号",
        prop: "batchNos",
        align: 'center',
      },
      {
        label: "出库档口",
        prop: "gateName",
        align: 'center',
      },
      {
        label: "出库类型",
        prop: "checkoutTypes",
        align: 'center',
        dicData: [
          {
            label: '销售出库',
            value: 1
          },
          {
            label: '损耗出库',
            value: 2
          }
        ],
      },
      {
        label: "出库类型",
        prop: "checkoutTypes",
        align: 'center',
        type: 'select',
        dicData: [
          {
            label: '销售出库',
            value: 1
          },
          {
            label: '损耗出库',
            value: 2
          }
        ],
        search: true,
        hide: true
      },
      {
        label: "经办人",
        prop: "handlerName",
        align: 'center',
      },
      {
        label: "出库时间",
        prop: "checkoutTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
      },
    ]
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ICheckOutQuery = deepCopy(this.queryParam);
    if (this.queryParam.checkoutTime && this.queryParam.checkoutTime.length) {
      form.checkoutTimeStart = this.queryParam.checkoutTime[0]
      form.checkoutTimeEnd = this.queryParam.checkoutTime[1]
    } else {
      form.checkoutTimeStart = ""
      form.checkoutTimeEnd = ""
    }
    delete form.checkoutTime
    form.gateIdList = form.gateIdList?.toString()
    checkoutExportApi(form).then(e => {
      exportFile(e, '出库记录.xlsx')
    })
  }

  getList() {
    const form: ICheckOutQuery = deepCopy(this.queryParam);
    if (this.queryParam.checkoutTime && this.queryParam.checkoutTime.length) {
      form.checkoutTimeStart = this.queryParam.checkoutTime[0]
      form.checkoutTimeEnd = this.queryParam.checkoutTime[1]
    } else {
      form.checkoutTimeStart = ""
      form.checkoutTimeEnd = ""
    }
    delete form.checkoutTime
    form.gateIdList = form.gateIdList?.toString()
    checkoutQueryApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.checkoutVoPage.records;
      this.tableTotal = e.checkoutVoPage.total
      this.checkoutQtyTotal = e.checkoutQtyTotal
    })
  }

  created() {
    this.getList()
  }
}
