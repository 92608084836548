/**
 * @name: 仓储管理-出库记录接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 仓储管理-出库记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import {ICheckOutQuery} from "@/apis/storage/checkout/types";


/**
 * 分页查询
 * @returns
 */
export const checkoutQueryApi = (param:ICheckOutQuery) => get("/admin/checkout/queryByPage",param)

/**
 * 出库记录导出Excel
 * @param params 查询参数
 * @returns
 */
export const checkoutExportApi = (params: ICheckOutQuery) => get("/admin/checkout/checkoutExport", params,"blob")
